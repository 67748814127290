'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitDevicesManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitDevicesManager', [
    '$mdDialog'
    'RestUtils'
    'DeviceTypeManager'
    'DeviceManager'
    'MundoDefaultManager'
    '$translate'
    (
      $mdDialog
      RestUtils
      DeviceTypeManager
      DeviceManager
      MundoDefaultManager
      $translate
    )->
      UnitDevicesManagerBase = new MundoDefaultManager()
      UnitDevicesManagerBase.setUrl('devices')
      UnitDevicesManagerBase.setAutoTenant()
      UnitDevicesManagerBase.setNewObject([
        'device',
        'deviceTypeProfile',
        'managed',
        'installed',
        'deviceTypeProfileSwitchingAllowed',
        'allowedDeviceTypeProfiles'
      ])
      UnitDevicesManagerBase.setParentUrl('units')

      UnitDevicesManagerBase.update = (id, data) ->
        if data.allowedDeviceTypeProfiles
          data.allowedDeviceTypeProfiles = (x.code for x in data.allowedDeviceTypeProfiles)

        @transport.one(@conf.parentUrl, @conf.parentId).one(@getUrl(), id)
          .customPUT(_.pick(data, @getUpdateObject()))

      UnitDevicesManagerBase.getEditForm = (data) ->
        devices = DeviceManager.getFullList({'sort' : 'label,ASC'}).$object

        allowedDeviceTypeProfiles = []
        if data.allowedDeviceTypeProfiles? and data.allowedDeviceTypeProfiles.length
          DeviceTypeManager.getListPromise().then (types) ->
            for i, type of types.results when type.code == data.device.deviceType
              for j, profile of type.profiles when profile.code in data.allowedDeviceTypeProfiles
                allowedDeviceTypeProfiles.push profile

        [
          key: 'device'
          name: 'device'
          type: 'select'
          defaultValue : data.device.id
          templateOptions:
            label: $translate.instant('app.admin.devices.device')
            placeholder: $translate.instant('app.admin.devices.device')
            required: true
            labelProp: "label"
            valueProp: "id"
            options: devices
        ,
          key: 'deviceTypeProfile'
          name: 'deviceTypeProfile'
          type: 'select'
          defaultValue : data.deviceTypeProfile
          templateOptions:
            label: $translate.instant('app.admin.devices.device-type-profile')
            placeholder: $translate.instant('app.admin.devices.device-type-profile')
            required: true
            labelProp: "label"
            valueProp: "code"
            options: DeviceTypeManager.getProfilesByType(data.device.deviceType)
          hideExpression: '!model.device'
          data: {
            devices: devices
          }
          controller: [
            '$scope'
            'DeviceTypeManager'
            ($scope, DeviceTypeManager)->
              $scope.$watch 'model.device', (device, oldValue, theScope) ->
                if not device.deviceType?
                  device = _.findWhere $scope.options.data.devices, {id: device}

                if device && device.deviceType?
                  $scope.to.options = DeviceTypeManager.getProfilesByType(device.deviceType)
          ]
        ,
          key: 'managed'
          name: 'managed'
          type: 'checkbox'
          hideExpression: '!model.device'
          defaultValue: data.managed
          templateOptions:
            label: $translate.instant('app.managed')
        ,
          key: 'installed'
          name: 'installed'
          type: 'checkbox'
          hideExpression: '!model.device'
          defaultValue: data.installed
          templateOptions:
            label: $translate.instant('app.installation')
        ,
          key: 'deviceTypeProfileSwitchingAllowed'
          name: 'deviceTypeProfileSwitchingAllowed'
          type: 'checkbox'
          hideExpression: '!model.device'
          defaultValue: data.deviceTypeProfileSwitchingAllowed
          templateOptions:
            label: $translate.instant('app.units.device-type-profile-switching-allowed')
        ,
          key: 'allowedDeviceTypeProfiles'
          name: 'allowedDeviceTypeProfiles'
          type: 'modelchips'
          hideExpression: '!model.device || !model.deviceTypeProfileSwitchingAllowed'
          templateOptions:
            label: $translate.instant('app.units.allowed-device-type-profiles')
            multiple: true,
            placeholder: 'allowedDeviceTypeProfiles'
            required: false
            labelProp: "label"
            valueProp: "code"
            options: DeviceTypeManager.getProfilesByType(data.device.deviceType)
          defaultValue: allowedDeviceTypeProfiles
          data: {
            devices: devices
          }
          controller: [
            '$scope'
            'DeviceTypeManager'
            ($scope, DeviceTypeManager)->
              $scope.$watch 'model.device', (device, oldValue, theScope) ->
                if not device.deviceType?
                  device = _.findWhere $scope.options.data.devices, {id: device}

                if device && device.deviceType?
                  $scope.to.options = DeviceTypeManager.getProfilesByType(device.deviceType)
          ]
        ]

      UnitDevicesManagerBase
  ]
